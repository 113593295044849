import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { CutoffRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CutoffService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get(
      `properties/cutoff?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  create(payload: Omit<CutoffRequest, 'id'>) {
    return this.http.post('properties/cutoff', payload);
  }

  update({ id, ...payload }: CutoffRequest) {
    return this.http.put(`properties/cutoff/${id}`, payload);
  }

  delete(itemId: number) {
    return this.http.delete(`properties/cutoff/${itemId}`);
  }
}
