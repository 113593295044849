import { createAction, props } from '@ngrx/store';

import { Cutoff, CutoffRequest } from '../../models';

export const loadRequest = createAction(
  '[Cutoff] Load Request',
  props<{ propertiesIds: number[] }>(),
);

export const loadSuccess = createAction(
  '[Cutoff] Load Success',
  props<{ items: Cutoff[] }>(),
);

export const loadFailure = createAction(
  '[Cutoff] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Cutoff] Create Request',
  props<{
    request: Omit<CutoffRequest, 'id'>;
  }>(),
);

export const createSuccess = createAction(
  '[Cutoff] Create Success',
  props<{ item: Cutoff }>(),
);

export const createFailure = createAction(
  '[Cutoff] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Cutoff] Update Request',
  props<{
    request: CutoffRequest;
  }>(),
);

export const updateSuccess = createAction(
  '[Cutoff] Update Success',
  props<{ item: Cutoff }>(),
);

export const updateFailure = createAction(
  '[Cutoff] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Cutoff] Delete Request',
  props<{
    itemId: number;
  }>(),
);

export const deleteSuccess = createAction(
  '[Cutoff] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Cutoff] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Cutoff] Reset State');
